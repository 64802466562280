import { Controller } from "stimulus"

export default class extends Controller {
    static targets = ["legal_address", "mailing_address",
    "legal_city", "mailing_city",
    "legal_zip", "mailing_zip", "legal_state", "mailing_state"]

    connect() {

    }
    same_address() {
        if (event.target.checked) {
            this.mailing_addressTarget.value=this.legal_addressTarget.value
            this.mailing_cityTarget.value=this.legal_cityTarget.value
            this.mailing_zipTarget.value=this.legal_zipTarget.value
            $("#web_applicant_mailing_state").val($("#web_applicant_state").val())
            $("#web_applicant_mailing_state").trigger("change");


        }

    }
}

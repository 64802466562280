import {Controller} from "stimulus"

export default class extends Controller {
    connect() {

    }

    nocitizen() {
       if (event.target.value == "false") {
         $( "#citizensection" ).show();
        }
    }

    yescitizen() {
       if (event.target.value == "true") {
         $( "#citizensection" ).hide();
        }
    }
    }

// This file is automatically compiled by Webpack, along with any other files
// present in this directory. You're encouraged to place your actual application logic in
// a relevant structure within app/javascript and only use these pack files to reference
// that code so it'll be compiled.

import Rails from "@rails/ujs"
import "@hotwired/turbo-rails"
import * as ActiveStorage from "@rails/activestorage"
import "channels"

Rails.start()
ActiveStorage.start()

import "controllers"
import "bootstrap"
import "@fortawesome/fontawesome-free/css/all"
import "../stylesheets/application";
require("jquery")
require("@nathanvda/cocoon")
require("dropzone")
import "jquery-mask-plugin"

$(document).on('ready turbolinks:load', () => {
    $('[data-toggle="tooltip"]').tooltip();
    $('[data-toggle="popover"]').popover();
});
window.jQuery = $;
window.$ = $;
$.jMaskGlobals.watchDataMask = true;

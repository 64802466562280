import {Controller} from "stimulus"

export default class extends Controller {
  static get targets() { return ['detail'] }

  toggle(event) {
    switch (event.target.value) {
      case 'True':
        this.detailTarget.style.display = '';
        break;
      case 'False':
        this.detailTarget.style.display = 'none';
        break;
      default:
        console.warn("Unexpected value", event.target.value)
    }
  }
}

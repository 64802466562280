import { Controller } from "stimulus"

export default class extends Controller {
    static values = { webexam:String }
    initialize() {

    }
    instructions() {
        const w_id=this.webexamValue;
        $("#applyButton").prop("href",`/apply/apply?w_id=${w_id}`);
        $("#instructionModal").modal('show')
        fetch(`/apply/get_instructions/${w_id}`)
            .then(response => response.text())
            .then(html => $("#instructionModal .modal-body").html(html))
    }
}
import {Controller} from "stimulus"

export default class extends Controller {
  connect() {
      if ($("#web_applicant_waiver_requested").is(':checked') == true) {
          $( "#waiversection" ).show();
          $( "#creditcardsection" ).hide();
      } else {
          $( "#waiversection" ).hide();
          $( "#creditcardsection" ).show();
      };
  }

    yeswaiver() {
       if (event.target.checked) {
         $( "#waiversection" ).show();
         $( "#creditcardsection" ).hide();
        } else {
           $( "#waiversection" ).hide();
           $( "#creditcardsection" ).show();

       }
    }
  }

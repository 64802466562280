import {Controller} from "stimulus"

export default class extends Controller {
  connect() {
      if ($("#web_applicant_army_credit_d_vet_true").is(':checked') == true) {
          $( "#creditsection" ).show();
      } else {
          $( "#creditsection" ).hide();
      };
  }
    yescredits() {
      event.preventDefault()
       if (event.target.value == "true") {
         $( "#creditsection" ).show();
        }
    }

    nocredits() {
       if (event.target.value == "false") {
         $( "#creditsection" ).hide();
        }
    }


  }

import {Controller} from "stimulus"

export default class extends Controller {
    connect() {

    }

    yespaid() {
       if (event.target.value == "true") {
         $( "#volsection" ).hide();
        }
    }

    nopaid() {
       if (event.target.value == "false") {
         $( "#volsection" ).show();
        }
    }
    }
